import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    title: String,
    text: String,
    url: String,
  };

  async share(event = undefined) {
    event?.preventDefault();
    event?.stopPropagation();

    const title = this.titleValue;
    const text = this.textValue;
    const url = this.urlValue;
    const data = { title, text, url };

    if ("share" in navigator && "canShare" in navigator) {
      if (navigator.canShare(data)) {
        try {
          await navigator.share(data);
        } catch (e) {
          switch (e.name) {
            case "AbortError":
              break;
            default:
              console.error(e);
          }
        }
      } else {
        console.error("No se puede compartir", data);
      }
    }
  }
}
