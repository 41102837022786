import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["instance"];
  static values = {
    uri: String,
  };

  revalid (event) {
    this.instanceTarget.classList.remove("is-invalid");
  }

  async submit (event) {
    this.toggle();

    // We're splitting by spaces to prevent mistypings
    const instanceParts = this.instanceTarget.value.split(/[@ ]/).map(x => x.trim()).filter(x => x.length);
    const instanceHost = (instanceParts.length === 1) ? instanceParts[0] : instanceParts[instanceParts.length - 1];

    const webfingerURL = `https://${instanceHost}/.well-known/webfinger?resource=https://${instanceHost}`;
    const signal = AbortSignal.any([window.abortController?.signal, AbortSignal.timeout(3000)]);
    const headers = { Accept: "application/json" };

    try {
      const response = await fetch(webfingerURL, { headers, signal });
      const webfinger = await response.json();
      const template = webfinger?.links?.find(x => x?.rel === "http://ostatus.org/schema/1.0/subscribe")?.template;

      if (!template) throw (new Error(`Webfinger ${webfingerURL} doesn't support remote interaction`));

      const uri = template.replaceAll("{uri}", encodeURIComponent(this.uriValue));

      setTimeout(() => Turbo.visit(uri), 100);
    } catch (e) {
      console.error(e);

      this.instanceTarget.classList.add("is-invalid");
    }

    this.toggle();
  }

  toggle() {
    for (const element of this.element.elements) {
      element.disabled = !element.disabled;
    }
  }
}
