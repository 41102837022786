import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["transmission", "source"];
  static values = {
    title: String,
    artist: String,
    artwork: String,
    type: String,
    sizes: String,
  };

  transmissionTargetConnected(transmissionTarget) {
    if (!("mediaSession" in window.navigator)) return;

    window.navigator.mediaSession.setActionHandler("play", () => transmissionTarget.play());
    window.navigator.mediaSession.setActionHandler("pause", () => transmissionTarget.pause());
    window.navigator.mediaSession.setActionHandler("nexttrack", () => this.restart());
  }

  connect() {
    if (!("mediaSession" in window.navigator)) return;

    window.navigator.mediaSession.metadata = new MediaMetadata({
      title: this.titleValue,
      artist: this.artistValue,
      artwork: [
        {
          src: this.artworkValue,
          sizes: this.sizesValue,
          type: this.typeValue,
        },
      ],
    });
  }

  restart() {
    for (const source of this.sourceTargets) {
      const url = new URL(source.src);

      url.search = `?${Date.now()}`;

      source.src = url.toString();
    }

    this.transmissionTarget.load();
    this.transmissionTarget.play();
  }
}
